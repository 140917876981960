var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "login justify-center",
      attrs: { fluid: "", "fill-height": "" }
    },
    [
      _c(
        "v-form",
        {
          ref: "loginForm",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleLogin()
            }
          },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-container",
            { staticClass: "login-inner background pa-sm-8 mx-auto" },
            [
              _c(
                "v-row",
                {
                  staticClass: "pa-2",
                  attrs: { align: "center", justify: "center" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("lottie-player", {
                        style: _vm.$vuetify.breakpoint.smAndDown
                          ? "height: 20vh"
                          : "",
                        attrs: {
                          src:
                            "https://assets6.lottiefiles.com/packages/lf20_y0zhnhcy.json",
                          background: "transparent",
                          speed: "1",
                          autoplay: "",
                          loop: ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "text-h4 justify-center secondary--text mb-5 font-weight-black"
                        },
                        [_vm._v("Welcome Back!")]
                      ),
                      _c("v-text-field", {
                        attrs: {
                          label: "Email ID",
                          rules: _vm.emailRules,
                          filled: "",
                          clearable: "",
                          autocomplete: "false",
                          type: "text",
                          required: ""
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Password",
                          rules: _vm.passwordRules,
                          type: _vm.passwordFieldType,
                          "append-icon": _vm.passwordIcon,
                          filled: "",
                          clearable: "",
                          autocomplete: "false",
                          required: ""
                        },
                        on: { "click:append": _vm.unmaskPassword },
                        model: {
                          value: _vm.password,
                          callback: function($$v) {
                            _vm.password = $$v
                          },
                          expression: "password"
                        }
                      }),
                      _c(
                        "router-link",
                        {
                          staticClass: "secondary--text",
                          attrs: { text: "", to: "/forgotpassword" }
                        },
                        [_vm._v("Forgot Password?")]
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-4", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-large": "",
                                    block: "",
                                    color: "success",
                                    type: "submit",
                                    disabled: !_vm.valid
                                  }
                                },
                                [_vm._v(" Login ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "8" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-large": "",
                                    block: "",
                                    color: "secondary",
                                    to: "/signup"
                                  }
                                },
                                [_vm._v(" Create a new account ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-large": "",
                                    block: "",
                                    color: "secondary",
                                    text: "",
                                    to: "/Landing"
                                  }
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "arrow-left", pull: "left" }
                                  }),
                                  _vm._v(" Go Back")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("Loading", {
                attrs: { loading: _vm.loading, text: _vm.loginLoadingText }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }