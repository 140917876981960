<template>
  <v-container class="login justify-center" fluid fill-height>
    <v-form ref="loginForm" v-model="valid" lazy-validation @submit.prevent="handleLogin()">
      <v-container class="login-inner background pa-sm-8 mx-auto">
        <v-row align="center" justify="center" class="pa-2">
          <v-col cols="12" md="6">
            <lottie-player
              src="https://assets6.lottiefiles.com/packages/lf20_y0zhnhcy.json"
              background="transparent"
              speed="1"
              autoplay
              :style="$vuetify.breakpoint.smAndDown ? 'height: 20vh' : ''"
              loop
            ></lottie-player>
          </v-col>
          <v-col cols="12" md="6">
            <h1 class="text-h4 justify-center secondary--text mb-5 font-weight-black">Welcome Back!</h1>
            <v-text-field
              v-model="email"
              label="Email ID"
              :rules="emailRules"
              filled
              clearable
              autocomplete="false"
              type="text"
              required
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="Password"
              :rules="passwordRules"
              :type="passwordFieldType"
              :append-icon="passwordIcon"
              filled
              clearable
              autocomplete="false"
              required
              @click:append="unmaskPassword"
            ></v-text-field>
            <router-link class="secondary--text" text to="/forgotpassword">Forgot Password?</router-link>
            <v-row align="center" class="mt-4">
              <v-col cols="12" md="4">
                <v-btn x-large block color="success" type="submit" :disabled="!valid"> Login </v-btn>
              </v-col>
              <v-col cols="12" md="8">
                <v-btn x-large block color="secondary" to="/signup"> Create a new account </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn x-large block color="secondary" text to="/Landing">
                  <font-awesome-icon icon="arrow-left" pull="left"></font-awesome-icon>
                  Go Back</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <Loading :loading="loading" :text="loginLoadingText" />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { login } from "@/api/AuthApi"
import { loginSignupMixin } from "@/mixins/loginSignupMixin.js"

export default {
  mixins: [loginSignupMixin],
  components: {
    Loading: () => import(/* webpackChunkName: "loading" */ "@/components/common/Loading.vue"),
  },
  metaInfo: {
    title: "Login and Create Quick, Fun & Simple Quizzes to Engage your audience",
    titleTemplate: "Qizto | %s ",
    meta: [
      {
        name: "description",
        content:
          "Qizto provides simple solutions that make teaching and learning easy for everyone, from the youngest students to faculty and corporate employees.",
      },
    ],
  },
  data() {
    return {
      tab: "login",
      loginLoadingText: "Logging you in...",
      loginSuccessMessage: "Logged In Successfully",
      email: "",
      password: "",
      snackbar: false,
      msg: null,
      dialog: false,
      errmsg: null,
    }
  },
  methods: {
    unmaskPassword() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password"
    },
    async handleLogin() {
      if (this.$refs.loginForm.validate()) {
        this.loading = !this.loading
        try {
          await login(this.email, this.password)
          this.showAlert(this.loginSuccessMessage)
          this.$router.push("/dashboard")
        } catch (err) {
          this.showAlert(err.message)
        } finally {
          this.loading = !this.loading
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  // background: rgb(255, 243, 224);
  // background:

  min-height: 90vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom left;
  background-image: url("~@/assets/background-bottom.svg"),
    linear-gradient(174deg, rgba(255, 243, 224, 1) 20%, rgba(255, 223, 173, 1) 78%);
  background-blend-mode: overlay;
  .login-inner {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
}
</style>
